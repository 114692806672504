(function () {
  'use strict';

  class CloneResourceFlow {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, node, callback) {
      const vm = this;
      vm.node = node;
      vm.$mdDialog.show({
        controller: 'CloneResourceCtrl',
        controllerAs: 'cloneResourceFlow',
        templateUrl: 'components/clone-resource-flow/clone-resource-flow.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
    getNode() {
      const vm = this;
      return vm.node;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:CloneResourceFlow
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('CloneResourceFlow', CloneResourceFlow);
}());
